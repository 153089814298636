import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SidebarModule } from 'primeng/sidebar';
import { PlaneDepartureIconComponent } from '../icons/plane-departure-icon/plane-departure-icon.component';
import { AccordionModule } from 'primeng/accordion';
import { PasswordModule } from 'primeng/password';
import { SkeletonModule } from 'primeng/skeleton';
import { TableSkeletonLoadingComponent } from './table-skelaton-loading/table-skeleton-loading.component';
import { ChipModule } from 'primeng/chip';
import { PlaneIconComponent } from '../icons/plane-icon/plane-icon.component';
import { ApiService } from 'src/app/services/generic/api.service';
import { ChartModule } from 'primeng/chart';
import { RippleModule } from 'primeng/ripple';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';
import { ProgressBarModule } from 'primeng/progressbar';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { FileUploadModule } from 'primeng/fileupload';
import { EditorModule } from 'primeng/editor';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ChipsModule } from 'primeng/chips';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';
import { AudioPlayerComponent } from 'src/app/media/audio-player/audio-player.component';
import { CheckPlayIconComponent } from '../icons/check-play-icon/check-play-icon.component';
import { CheckDownloadIconComponent } from '../icons/check-download-icon/check-download-icon.component';


@NgModule({
  declarations: [
    PlaneDepartureIconComponent,
    TableSkeletonLoadingComponent,
    PlaneIconComponent,
    AudioPlayerComponent,
    CheckPlayIconComponent,
    CheckDownloadIconComponent
  ],
  imports: [
    TableModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputSwitchModule,
    DialogModule,
    TranslateModule,
    ToastModule,
    DynamicDialogModule,
    InputNumberModule,
    InputTextModule,
    TooltipModule,
    InputTextModule,
    SidebarModule,
    AccordionModule,
    PasswordModule,
    SkeletonModule,
    CommonModule,
    ChipModule,
    AvatarModule,
    FileUploadModule,
    EditorModule,
    BadgeModule,
    OverlayPanelModule,
    InputGroupModule,
    InputGroupAddonModule,
    ChipsModule,
    TimelineModule,
    CardModule,
    SplitButtonModule,
    CalendarModule,
    DividerModule,

    RippleModule,
    TagModule,
    ChartModule,
    AvatarGroupModule,
    ProgressBarModule,
    PanelModule,
    TabViewModule,
    MultiSelectModule

  ],

  exports: [
    TableModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputSwitchModule,
    DialogModule,
    TranslateModule,
    TooltipModule,
    ToastModule,
    DynamicDialogModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    SidebarModule,
    AccordionModule,
    PasswordModule,
    SkeletonModule,
    ChipModule,
    AvatarModule,
    RippleModule,
    TagModule,
    ChartModule,
    AvatarGroupModule,
    ProgressBarModule,
    PanelModule,
    TabViewModule,
    FileUploadModule,
    EditorModule,
    BadgeModule,
    TimelineModule,
    OverlayPanelModule,
    InputGroupModule,
    DividerModule,

    InputGroupAddonModule,
    ChipsModule,
    CardModule,
    SplitButtonModule,
    CalendarModule,

    PlaneDepartureIconComponent,
    TableSkeletonLoadingComponent,
    PlaneIconComponent,
    AudioPlayerComponent,
    CheckPlayIconComponent,
    CheckDownloadIconComponent,
    MultiSelectModule
  ],
  providers: [ApiService],
})
export class SharedModule { }