export const DATACENTERS = [
  {
    label: 'docker201.lan / US-EAST-1',
    value: 'docker201.lan',
  },
  {
    label: 'docker203.lan / US-EAST-2',
    value: 'docker203.lan',
  },
];

export const AWS_REGIONS = [
  {
    label: 'North America (us-east-1)',
    value: 'us-east-1',
  },
  {
    label: 'Australia (ap-southeast-2)',
    value: 'ap-southeast-2',
  },
];

export const RATECARDS = [
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'European Union',
    value: 'EU',
  },
  {
    label: 'Australia',
    value: 'Regular',
  },
  {
    label: 'World (others)',
    value: 'World',
  },
];

export const PRIORITIES = [
  {
    label: 'High',
    value: 0,
  },
  {
    label: 'Medium',
    value: 1,
  },
  {
    label: 'Low',
    value: 2,
  }
];

export enum OperationType {
  Create,
  Update,
  Delete,
  Activate,
  Deactivate,
}

export enum Provider {
  SkySwitch,
  Twilio
}
export enum FormClosedType {
  WithoutDataSaved,
  WithDataSaved
}

export enum UserState {
  Active,
  Inactive,
  Deleted
}

export enum CustomerState {
  Active,
  Inactive,
  Deleted
}

export enum ProviderState {
  Active,
  Deleting
}
export enum ApiKeyState {
  Active,
  Inactive
}
export enum IDPProvider {
  AWSConginto,
  AzureB2C
}

export const LANGUAGES = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Français',
    value: 'fr',
  },

];


export enum FilterType {
  None,
  Name,
  FromNumber,
  ToNumber,
  Direction,
  Duration,
  Date,
  Dialed,
  Status
}
export enum DirectionFilterType {
  Inbound,
  Outbound,
  Internal
}
export enum DirectionFilterValue {
  "in",
  "out",
  "internal"
}

export enum DurationType {
  Between,
  ShorterThan,
  LongerThan
}
export enum DateType {
  Between,
  Before,
  After
}

export enum AudioState {
  Start,
  Play,
  Pause,
  End
}

export enum UserRole {
  SuperAdmin,
  Admin,
  BaseUser
}

export enum LegacyFilterType {
  None,
  Status,
  Name,
  Extension,
  ExternalPhoneNumber,
  InternalPhoneNumber,
  Tag,
  Duration,
  Date,
  Direction,
  Id
}

export enum ZohoIntegrationAction {
  Refresh,
  Activate,
  Deactivate,

}

export const LegacyCdrTagsType = [
  {
    label: 'ABC',
    value: 'ABC',
  },
  {
    label: '911-TECH',
    value: '911-TECH',
  },
  {
    label: '277',
    value: '277',
  },
  {
    label: '234-FWD',
    value: '234-FWD',
  },
  {
    label: 'Acc EN',
    value: 'Acc EN',
  },
  {
    label: 'Acc FR',
    value: 'Acc FR',
  },
  {
    label: 'Accounting',
    value: 'Accounting',
  },
  {
    label: 'Afterhours',
    value: 'Afterhours',
  },
  {
    label: 'Bruh',
    value: 'Bruh',
  },
  {
    label: 'CHARLES',
    value: 'CHARLES',
  },
  {
    label: 'CSR',
    value: 'CSR',
  },
  {
    label: 'DING',
    value: 'DING',
  },
  {
    label: 'DONG',
    value: 'DONG',
  },
  {
    label: 'Direct',
    value: 'Direct',
  },
  {
    label: 'EN',
    value: 'EN',
  },
  {
    label: 'ENGLISH',
    value: 'ENGLISH',
  },
  {
    label: 'En',
    value: 'En',
  },
  {
    label: 'English',
    value: 'English',
  },
  {
    label: 'FO',
    value: 'FO',
  },
  {
    label: 'FO-Sales',
    value: 'FO-Sales',
  },
  {
    label: 'FO-SupEN',
    value: 'FO-SupEN',
  },
  {
    label: 'FO-SupFR',
    value: 'FO-SupFR',
  },
  {
    label: 'FO-Vente',
    value: 'FO-Vente',
  },
  {
    label: 'FO2',
    value: 'FO2',
  },
  {
    label: 'France',
    value: 'France',
  },
  {
    label: 'HD',
    value: 'HD',
  },
  {
    label: 'HD-FAILOVER',
    value: 'HD-FAILOVER',
  },
  {
    label: 'HD1',
    value: 'HD1',
  },
  {
    label: 'HD2',
    value: 'HD2',
  },
  {
    label: 'Holand',
    value: 'Holand',
  },
  {
    label: 'NIGHT-HD',
    value: 'NIGHT-HD',
  },
  {
    label: 'PRE-TEST',
    value: 'PRE-TEST',
  },
  {
    label: 'QUESTION',
    value: 'QUESTION',
  },
  {
    label: 'QUESTION \?',
    value: 'QUESTION \?',
  },
  {
    label: 'SAC EN',
    value: 'SAC EN',
  },
  {
    label: 'SAC ES',
    value: 'SAC ES',
  },
  {
    label: 'SAC FR',
    value: 'SAC FR',
  },
  {
    label: 'SOS',
    value: 'SOS',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Sales EN',
    value: 'Sales EN',
  },
  {
    label: 'Spec',
    value: 'Spec',
  },
  {
    label: 'Sup EN',
    value: 'Sup EN',
  },
  {
    label: 'Sup FR',
    value: 'Sup FR',
  },
  {
    label: 'Test',
    value: 'Test',
  },
  {
    label: 'VM',
    value: 'VM',
  },
  {
    label: 'Vente',
    value: 'Vente',
  },
  {
    label: 'Vente FR',
    value: 'Vente FR',
  },
  {
    label: 'hehehe',
    value: 'hehehe',
  },
  {
    label: 'queue',
    value: 'queue',
  },
  {
    label: 'test',
    value: 'test',
  },
];


// export const LegacyCdrTagsType = [
//   "ABC",
//   "911-TECH",
//   "277",
//   "234-FWD",
//   "Acc EN",
//   "Acc FR",
//   "Accounting",
//   "Afterhours",
//   "Bruh",
//   "CHARLES",
//   "CSR",
//   "DING",
//   "DONG",
//   "Direct",
//   "EN",
//   "ENGLISH",
//   "En",
//   "English",
//   "FO",
//   "FO-Sales",
//   "FO-SupEN",
//   "FO-SupFR",
//   "FO-Vente",
//   "FO2",
//   "France",
//   "HD",
//   "HD-FAILOVER",
//   "HD1",
//   "HD2",
//   "Holand",
//   "NIGHT-HD",
//   "PRE-TEST",
//   "QUESTION",
//   "QUESTION \?",
//   "SAC EN",
//   "SAC ES",
//   "SAC FR",
//   "SOS",
//   "Sales",
//   "Sales EN",
//   "Spec",
//   "Sup EN",
//   "Sup FR",
//   "Test",
//   "VM",
//   "Vente",
//   "Vente FR",
//   "hehehe",
//   "queue",
//   "test"
// ]

export const LegacyCdrStatusType = [
  "Connected",
  "Voicemail",
  "No Answer",
  "Missed"
]

export enum SkySwitchIntegrationStatus {
  NoIntegration,
  Integrating,
  Importing,
  Integrated
}